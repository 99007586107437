import React from "react"

const Contact = props => {
  const { contact, title } = props
  return (
    <div className="flex-col">
      <h5 className="resume-title-text pb-4">{title}</h5>
      {contact?.map((item, i) => {
        return (
          <div
            key={"contact" + i}
            className="flex flex-col pb-6 pr-4 print:pb-3"
          >
            <h6 className="font-semibold">{item.contact_name.text}</h6>
            <span className="font-light truncate">
              {item.phone.text} / {item.email.text}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default Contact
