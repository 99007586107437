import React, { Fragment } from "react"

const Certificates = props => {
  const { title, certificates } = props

  if (certificates?.length > 0 && !certificates[0]?.certificate?.html)
    return false

  return (
    <Fragment>
      <div className="py-12">
        <div className="flex-col">
          <div className="flex px-6 md:px-0 print:px-0">
            <div className="pl-12 pr-10 hidden md:block print:block">
              <svg
                className="resume-svg-icons"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 379.81 384"
              >
                <defs>
                  <clipPath id="clip-path" transform="translate(-0.09)">
                    <rect className="svg-img-fill" width="380" height="384" />
                  </clipPath>
                </defs>
                <path
                  className="svg-img-fill"
                  d="M192.08,136.13h-144a5.63,5.63,0,0,0,0,11.25h144a5.63,5.63,0,0,0,0-11.25Z"
                  transform="translate(-0.09)"
                />
                <path
                  className="svg-img-fill"
                  d="M192.08,175.65h-144a5.63,5.63,0,0,0,0,11.25h144a5.63,5.63,0,0,0,0-11.25Z"
                  transform="translate(-0.09)"
                />
                <path
                  className="svg-img-fill"
                  d="M192.08,215.17h-144a5.63,5.63,0,0,0,0,11.25h144a5.63,5.63,0,0,0,0-11.25Z"
                  transform="translate(-0.09)"
                />
                <path
                  className="svg-img-fill"
                  d="M197.7,260.32a5.61,5.61,0,0,0-5.62-5.62h-144a5.63,5.63,0,0,0,0,11.25h144a5.62,5.62,0,0,0,5.62-5.63Z"
                  transform="translate(-0.09)"
                />
                <path
                  className="svg-img-fill"
                  d="M48.09,312.62a5.62,5.62,0,0,0,0,11.24h72.18a5.62,5.62,0,0,0,0-11.24Z"
                  transform="translate(-0.09)"
                />
                <path
                  className="svg-img-fill"
                  d="M256.89,69.64V51.39a12.62,12.62,0,0,0-12.6-12.61H128.81A12.63,12.63,0,0,0,116.2,51.39V69.64a12.63,12.63,0,0,0,12.61,12.61h9.75a5.63,5.63,0,1,0,0-11.25h-9.75a1.36,1.36,0,0,1-1.36-1.36V51.39A1.36,1.36,0,0,1,128.81,50H244.29a1.35,1.35,0,0,1,1.35,1.36V69.64A1.35,1.35,0,0,1,244.29,71H164.86a5.63,5.63,0,1,0,0,11.25h79.43a12.62,12.62,0,0,0,12.6-12.61Z"
                  transform="translate(-0.09)"
                />
                <g className="svg-img-fill">
                  <path
                    className="svg-img-fill"
                    d="M299,343a5.62,5.62,0,0,0-5.62,5.63v7.57a16.6,16.6,0,0,1-16.58,16.58H27.93a16.6,16.6,0,0,1-16.59-16.58V85.82a17,17,0,0,1,.4-3.57H63A19.41,19.41,0,0,0,82.34,62.86V11.64a17,17,0,0,1,3.57-.39H276.75a16.59,16.59,0,0,1,16.57,16.58v74.88a5.63,5.63,0,1,0,11.25,0V27.83A27.85,27.85,0,0,0,276.75,0H85.92A27.61,27.61,0,0,0,66.24,8.15l-58,58A27.66,27.66,0,0,0,.09,85.82V356.17A27.86,27.86,0,0,0,27.93,384H276.75a27.85,27.85,0,0,0,27.82-27.83V348.6A5.62,5.62,0,0,0,299,343ZM71.09,19.2V62.86A8.15,8.15,0,0,1,63,71H19.3Z"
                    transform="translate(-0.09)"
                  />
                  <path
                    className="svg-img-fill"
                    d="M376,201a22.49,22.49,0,0,0-11-33.83,11.2,11.2,0,0,1-7.44-10.24A22.48,22.48,0,0,0,328.78,136a11.17,11.17,0,0,1-12-3.92,22.51,22.51,0,0,0-35.58,0,11.16,11.16,0,0,1-12,3.92,22.48,22.48,0,0,0-28.77,20.91,11.17,11.17,0,0,1-7.44,10.24,22.49,22.49,0,0,0-11,33.83,11.18,11.18,0,0,1,0,12.66,22.49,22.49,0,0,0,11,33.83,11.17,11.17,0,0,1,7.44,10.24,22.47,22.47,0,0,0,19.42,21.6v51.21A11.93,11.93,0,0,0,278,340.7L298.59,328a.6.6,0,0,1,.72,0l20.63,12.7a11.94,11.94,0,0,0,18.2-10.16V279.33a22.49,22.49,0,0,0,19.42-21.6A11.17,11.17,0,0,1,365,247.49a22.49,22.49,0,0,0,11-33.83A11.18,11.18,0,0,1,376,201ZM325.84,331.12l-20.63-12.69a11.91,11.91,0,0,0-12.52,0l-20.62,12.69a.68.68,0,0,1-1.05-.58V278.26a11.16,11.16,0,0,1,10.14,4.29,22.51,22.51,0,0,0,35.58,0,11.15,11.15,0,0,1,10.15-4.29v52.28a.68.68,0,0,1-1.05.58ZM366.69,220a11.25,11.25,0,0,1-5.49,16.91,22.35,22.35,0,0,0-14.89,20.49,11.24,11.24,0,0,1-14.38,10.45,22.36,22.36,0,0,0-24.09,7.82,11.23,11.23,0,0,1-17.78,0A22.36,22.36,0,0,0,266,267.84a11.17,11.17,0,0,1-10.34-2.15,11,11,0,0,1-4-8.3A22.37,22.37,0,0,0,236.7,236.9,11.25,11.25,0,0,1,231.21,220a22.37,22.37,0,0,0,0-25.33,11.23,11.23,0,0,1,5.49-16.9,22.38,22.38,0,0,0,14.89-20.49A11.23,11.23,0,0,1,266,146.82,22.37,22.37,0,0,0,290.06,139a11.24,11.24,0,0,1,17.78,0,22.38,22.38,0,0,0,24.09,7.83,11.23,11.23,0,0,1,14.38,10.45,22.34,22.34,0,0,0,14.89,20.48,11.25,11.25,0,0,1,5.49,16.91,22.37,22.37,0,0,0,0,25.33Z"
                    transform="translate(-0.09)"
                  />
                </g>
                <path
                  className="svg-img-fill"
                  d="M299,160.19a47.14,47.14,0,1,0,47.14,47.14A47.2,47.2,0,0,0,299,160.19Zm0,83a35.89,35.89,0,1,1,35.89-35.89A35.93,35.93,0,0,1,299,243.22Z"
                  transform="translate(-0.09)"
                />
              </svg>
            </div>

            <div className="flex-col pr-32">
              <h4 className="resume-title-text pb-6">{title}</h4>
              {certificates?.map((item, i) => {
                return (
                  <article key={"certificate" + i} className="dont-break">
                    <div className="flex-col justify-between">
                      <h6>{item.certificate.text}</h6>
                    </div>
                  </article>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Certificates
