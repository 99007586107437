import React from "react"

const Branches = props => {

  const { title, industries } = props;

  if (!industries) return false

  return (
    <div className="pl-4 pr-2">
      <h4 className="qlresumegray resume-title-text max-50-pr pb-2">{title}</h4>
      <span className="pt-2 qlresumegray resume-branch-content">{industries}</span>
    </div>
  )
}

export default Branches