import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Contact from "../components/resume/contact"
import Skills from "../components/resume/skills"
import Introduction from "../components/resume/introduction"
import Education from "../components/resume/education"
import Experience from "../components/resume/experience"
import ImageHeader from "../components/resume/imageHeader"
import Years from "../components/resume/years"
import Industries from "../components/resume/industries"
import Personality from "../components/resume/personality"
import Certificates from "../components/resume/certificates"
import SideNavigation from "../components/sideNavigation/sideNavigation"
import { appContext } from "../context/state"
import SEO from "../components/seo"
import "../styles/resume.css"

export const query = graphql`
  query($uid: String, $lang: String, $brand: String) {
    site {
      siteMetadata {
        title
      }
    }
    allPrismicCustomtemplate(filter: { uid: { eq: $brand } }) {
      edges {
        node {
          data {
            company_address {
              text
            }
            company_city {
              text
            }
            company_logo {
              url
            }
            company_logo_secondary {
              url
            }
            company_title {
              text
            }
            company_url {
              text
            }
            header_color
          }
        }
      }
    }
    allPrismicResumetranslations(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            certificates {
              text
            }
            contact {
              text
            }
            education {
              text
            }
            experience_skills {
              text
            }
            industries {
              text
            }
            personality {
              text
            }
            presentation {
              text
            }
            skills {
              text
            }
            work_experience {
              text
            }
            years_of_experience {
              text
            }
            branding_select {
              text
            }
          }
        }
      }
    }
    allPrismicResume(filter: { uid: { eq: $uid }, lang: { eq: $lang } }) {
      edges {
        node {
          data {
            contact {
              contact_name {
                text
              }
              email {
                text
              }
              phone {
                text
              }
            }
            description {
              text
              html
            }
            education {
              courses {
                text
              }
              duration {
                text
              }
              school {
                text
              }
              subject {
                text
              }
              summary {
                text
              }
            }
            body {
              ... on PrismicResumeBodyWorkExperience {
                primary {
                  duration {
                    text
                  }
                  employer {
                    text
                  }
                  role {
                    text
                  }
                }
                items {
                  client {
                    text
                  }
                  duration {
                    text
                  }
                  role {
                    text
                  }
                  summary {
                    text
                    html
                  }
                  techniques {
                    text
                  }
                }
              }
            }
            featured_image {
              url
              dimensions {
                height
                width
              }
            }
            introduction {
              text
              html
            }
            name {
              text
              html
            }
            skills {
              skill {
                text
              }
            }
            certificates {
              certificate {
                html
                text
              }
            }
            personality {
              personality_description {
                text
              }
              personality_image {
                url
              }
            }
            years_of_experience {
              text
            }
            industry {
              text
            }
          }
        }
      }
    }
  }
`
const ResumeTemplate = ({ location, pageContext, ...props }) => {
  const context = useContext(appContext)

  useEffect(() => {
    if (context) {
      const { changeEmployee, setTranslations, setBrand } = context
      setBrand(props.data.allPrismicCustomtemplate.edges[0].node.data)
      setTranslations(
        props.data.allPrismicResumetranslations?.edges[0]?.node.data
      )
      changeEmployee(props.data.allPrismicResume?.edges[0]?.node.data)
    }
  }, [])

  const GetTranslationTexts = () => {
    const translation =
      props.data.allPrismicResumetranslations?.edges[0]?.node.data

    return {
      yearsOfExperience: translation.years_of_experience[0].text,
      industries: translation.industries[0].text,
      skills: translation.skills[0].text,
      personality: translation.personality[0].text,
      presentation: translation.presentation[0].text,
      contact: translation.contact[0].text,
      experience: translation.work_experience[0].text,
      experienceSkills: translation.experience_skills[0].text,
      education: translation.education[0].text,
      certificates: translation.certificates[0].text,
      branding: translation.branding_select[0].text,
    }
  }

  const GetResume = () => {
    const personData = props.data.allPrismicResume?.edges[0]?.node.data
    const siteTitle = props.data.site.siteMetadata.title
    const nameArr = personData?.name?.text.split(" ")

    return {
      title: siteTitle,
      name: personData?.name?.text,
      firstName: nameArr === undefined ? "" : nameArr[0],
      lastName: nameArr === undefined ? "" : nameArr[1],
      role: personData?.description?.text,
      image: {
        alt: personData?.name?.text,
        url: personData?.featured_image?.url,
      },
      yearsOfExperience: personData?.years_of_experience.text,
      industries: personData?.industry.text,
      skills: personData?.skills,
      certificates: personData?.certificates,
      contact: personData?.contact,
      presentation: personData?.introduction,
      personality: personData?.personality,
      workExperience: personData?.body,
      education: personData?.education,
    }
  }

  let customTemplate = props.data.allPrismicCustomtemplate.edges[0].node.data

  const template = {
    isCustomTemplate: true,
    logoUrl: customTemplate.company_logo.url,
    backgroundColor: customTemplate.header_color,
    altText: customTemplate?.company_title[0]?.text,
    city: customTemplate?.company_city[0]?.text,
    url: customTemplate?.company_url[0]?.text,
    address: customTemplate?.company_address[0]?.text,
    printLogoHeader: customTemplate.company_logo.url,
    printLogoFooter: customTemplate.company_logo_secondary.url,
  }

  const resume = GetResume()
  const translation = GetTranslationTexts()

  const hasPersonalityTraits =
    resume?.personality?.length > 0 &&
    resume?.personality[0].personality_description?.text

  return (
    <Layout
      location={props.location}
      title={resume.title}
      branding={translation.branding}
    >
      <SEO
        title={resume.name}
        description={resume.presentation?.text}
        lang={"sv"}
        meta={[{ property: "og:image", content: resume.image.url }]}
      />
      {pageContext?.sideBar && (
        <div className='sidebar-width'>
          <SideNavigation location={location}></SideNavigation>
        </div>
      )}
      <div className={location.pathname.includes('/meet/') ? 'resume-meet-width' : 'resume-width'}>
        <section className="p-2 md:p-4 md:pt-12">
          <ImageHeader resume={resume} template={template} />
          <div className="flex flex-col md:flex-row print:flex-row pt-2 md:pt-4">
            <div
              className={
                "resume-left-width flex flex-col pl-2 print:pt-2 pt-4 md:pt-8"
              }
              style={{
                backgroundColor:
                  pageContext.brand === "queenslab"
                    ? template.backgroundColor
                    : "#DADADA",
              }}
            >
              {resume.yearsOfExperience && (
                <div className="flex flex-row pl-4 pr-4 md:pl-8 md:pr-8">
                  <div className="flex flex-row pb-4 resume-border-bottom">
                    <Years
                      resume={resume}
                      title={translation?.yearsOfExperience}
                    />
                    <Industries
                      industries={resume?.industries}
                      title={translation.industries}
                    />
                  </div>
                </div>
              )}
              {resume.personality?.[0]?.personality_description?.text && (
                <div className="flex p-4 md:p-8">
                  <div className="flex w-full pb-4 resume-border-bottom">
                    <Personality
                      personality={resume.personality}
                      title={translation.personality}
                      template={template}
                    />
                  </div>
                </div>
              )}
              <div className="flex px-4 pb-4 md:pl-8">
                <div className="flex w-full pb-12">
                  <Contact
                    contact={resume.contact}
                    title={translation.contact}
                  />
                </div>
              </div>
            </div>
            <div className="flex pl-4 print:pl-2">
              <div className="flex flex-col pt-8 print:pt-6 md:pl-16 md:pr-16 print:pl-8 print:pr-8 resume-bg-lightgrey">
                <div
                  className={
                    "flex " +
                    (pageContext.brand === "queenslab"
                      ? "resume-border-bottom-qlpink"
                      : "resume-border-bottom-gray")
                  }
                >
                  <Introduction
                    introduction={resume.presentation}
                    title={translation.presentation}
                  />
                </div>
                <div className="flex pt-8 pb-8 print:pt-8 print:pb-0">
                  <Skills
                    skills={resume.skills}
                    title={translation.skills}
                    template={pageContext.brand}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="p-2 md:p-4 md:pt-12">
            <Experience
              workExperience={resume.workExperience}
              title={translation.experience}
              title2={translation.experienceSkills}
              template={pageContext.brand}
            />
          </div>
        </section>
        <section className="resume-print-break">
          <Education
            education={resume.education}
            title={translation.education}
          />
          <Certificates
            certificates={resume.certificates}
            title={translation.certificates}
          />
        </section>
      </div>
    </Layout>
  )
}

export default ResumeTemplate
