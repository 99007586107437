import React from "react"

const Personality = props => {
  const { personality, title, template } = props

  if (personality?.length > 0 && !personality[0]?.personality_description?.text)
    return false

  return (
    <div>
      <h4 className="pb-6 resume-title-text">{title}</h4>
      {personality?.map((item, i) => (
        <div className="flex flex-row mb-4" key={"personality" + i}>
          <div className="w-16 relative">
            <div className="bg-qlwhite w-16 h-16 rounded-full opacity-50 relative" absolute></div>
            <div className="w-8 h-8 absolute top-4 left-4">
              <img className='w-full'
                src={item.personality_image.url}
                alt={item.personality_description.text}
              />
            </div>
          </div>

          <p className="p-4 text-base text-left font-light">
            {item.personality_description.text}
          </p>
        </div>
      ))}
    </div>
  )
}

export default Personality
