import React, { Fragment } from "react"
import ReactHtmlParser from "react-html-parser"
import Skill from "./skill"

const Experience = props => {
  const { title, title2, workExperience, template } = props
  let printCssBreakCounter = 0
  const renderSkills = skills => {
    if (skills === null) {
      return
    }

    const skillsArray = skills.split(", ")

    return skillsArray.map((technique, index) => {
      return (
        <Skill key={"skills" + index} skill={technique} template={template} />
      )
    })
  }

  let allExperience = []
  const experiences = workExperience?.forEach((assignment, i) => {
    assignment.items.forEach((experience, index) => {
      allExperience.push(experience)
    })
  })

  const renderExperience = (experience, i) => {
    if (experience?.client?.text === "") return

    let content = (
      <article key={"first_assignment" + i} className="dont-break">
        <div className="flex">
          <ul style={{width: "100%"}}>
            <li key={"workexperience" + i} className="mb-12 mt-6 dont-break">
              <h6 className="resume-experience-role-text mb-2">
                {experience.role.text}
              </h6>
              <div className="flex justify-between">
                <h6 className="resume-experience-company-text mb-2">
                  {experience.client.text} / {experience.duration.text}
                </h6>
              </div>
              <h6 className="resume-presentation-text">
                {ReactHtmlParser(experience.summary.html)}
              </h6>
              {experience.techniques?.text && (
                  <div className="skills mt-2 flex flex-wrap pt-2">
                    {renderSkills(experience.techniques.text)}
                  </div>
              
              )}
            </li>
          </ul>
        </div>
      </article>
    )

    return content
  }

  return (
        <div className="flex">
          <div className="md:pl-12 print:pl-12 md:pr-10 md:pt-2 hidden md:block print:block">
            <svg
              className="resume-svg-icons"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 143.89 144.85"
            >
              <defs></defs>
              <title>goal</title>
              <g id="Lager_2" data-name="Lager 2">
                <g id="Lager_1-2" data-name="Lager 1">
                  <g id="Gn1DPE.tif">
                    <path
                      className="svg-img-fill"
                      d="M37.8,93.07c2.05-3.89,3.79-7.16,5.49-10.45C49.6,70.43,55.94,58.26,62.14,46a13.3,13.3,0,0,0,1.37-5.67c.12-12,.06-24,.07-36,0-3.74.6-4.33,4.33-4.33C77.07,0,86.24-.06,95.4.1a6.56,6.56,0,0,1,4,1.79c3.7,3.6,7.15,7.45,10.76,11.14,1.6,1.63,1.59,3.1.12,4.78-3.68,4.2-7.21,8.53-11,12.62a6.34,6.34,0,0,1-4,1.79c-7.83.17-15.66.09-23.49.09H68.69c.21,4.37-.89,8.52,1.36,12.7,7.87,14.65,15.51,29.43,23.24,44.16.56,1.08,1.15,2.14,2,3.76,1.16-3,2.13-5.43,3.06-7.87,3-7.93,6-15.88,9.08-23.76.44-1.11,1.8-2.58,2.72-2.57s2.26,1.49,2.7,2.61q15.27,39.27,30.37,78.62c1.41,3.66.53,4.89-3.41,4.89H4.13c-4.16,0-5-1.22-3.35-5L26.1,81a4.71,4.71,0,0,1,.75-1.56c.78-.72,1.68-1.72,2.58-1.78a3.29,3.29,0,0,1,2.3,1.76C33.75,83.65,35.59,88,37.8,93.07ZM66.54,48.89c-.54.81-.85,1.19-1.07,1.61-8.1,15.66-16.21,31.32-24.21,47a4.55,4.55,0,0,0,0,3.42c5.28,12.3,10.7,24.53,16,36.83C58,139.54,59,140,60.82,140c16.83-.07,33.65,0,50.48-.05.8,0,1.61-.07,3-.14Zm-36.89,38-.7,0C21.4,104.47,13.86,122,6.2,139.8H52.78C45,122,37.32,104.43,29.65,86.89Zm80.74-18.44c-.38.4-.54.49-.59.63-3.65,9.54-7.32,19.08-10.89,28.65a3.6,3.6,0,0,0,.26,2.57q9.9,19,19.94,38c.37.69,1.23,1.59,1.88,1.6,5.48.13,11,.07,17,.07C128.68,115.93,119.56,92.27,110.39,68.45Zm-4.7-52.91c-3.25-3.41-6.31-6.68-9.46-9.85A3.42,3.42,0,0,0,94,5c-7.73,0-15.47,0-23.2-.06-1.54,0-2.25.4-2.22,2.1q.12,9.11,0,18.21c0,1.59.48,2.21,2.13,2.2,7.73-.07,15.46,0,23.2-.06a3,3,0,0,0,2-.65C99.19,23.09,102.36,19.37,105.69,15.54Z"
                    />
                    <path
                      className="svg-img-fill"
                      d="M104.7,130.87c-.49.59-.94,1.61-1.61,1.79a3.9,3.9,0,0,1-2.73-.47c-.63-.38-1-1.32-1.35-2.05Q87.25,108.75,75.5,87.34c-.2-.36-.4-.72-.59-1.09-.72-1.42-1.22-3,.41-4s3-.13,4,1.63c8.08,14.8,16.23,29.56,24.33,44.34A22.45,22.45,0,0,1,104.7,130.87Z"
                    />
                    <path
                      className="svg-img-fill"
                      d="M82.14,18c3.23-2.74,6.34-5.37,9.42-8C92.9,8.8,94.37,7.7,95.87,9.4s.41,3.14-1.09,4.39c-3.58,3-7.09,6.07-10.66,9.07-2,1.64-3.18,1.52-4.86-.43a36.07,36.07,0,0,1-4.06-5.05,3.83,3.83,0,0,1,.08-3.46c1-1.28,2.53-.91,3.64.35S81,16.64,82.14,18Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className="flex-col w-full px-4 md:px-0 md:pr-32 print:pr-32">
            <h4 className="resume-title-text">{title}</h4>
            {allExperience.map((experience, index) => {
              return renderExperience(experience, index)
            })}
          </div>
        </div>
  )
}

export default Experience
