import React from "react"

const Years = props => {
  const { title, resume } = props

  if (!resume?.yearsOfExperience) return false

  return (
    <div className="resume-border-right pr-4 max-50-pr">
      <h4 className="qlresumegray resume-title-text pb-2">{title}</h4>
      <p className="qlresumegray resume-experience-year">
        {resume?.yearsOfExperience}
      </p>
    </div>
  )
}

export default Years
