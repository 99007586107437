import React from "react"
import ReactHtmlParser from "react-html-parser"

const Head = props => {
  const { introduction, title } = props
  return (
    <div className="pb-10 flex flex-col">
      <h6 className="resume-title-text pb-6">{title}</h6>
      <h6 className="resume-presentation-text">
        {ReactHtmlParser(introduction?.html)}
      </h6>
    </div>
  )
}

export default Head
