import React from "react"
import Skill from "./skill"

const Skills = props => {
  const { skills, title, template } = props

  return (
    <div>
      <h4 className="qlresumegray resume-title-text pb-8">{title}</h4>
      <div className="flex flex-wrap">
        {skills?.map(({ skill }, i) => (
          <Skill key={"skills" + i} skill={skill.text} template={template} />
        ))}
      </div>
    </div>
  )
}

export default Skills
