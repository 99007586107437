import React from "react"
import { IsColorBright } from "../layout/customTemplate"

const TITLES = ["von", "van", "der", "den", "det"]

const ResumeImageHeader = props => {
  const { template, resume } = props

  const properLastname = TITLES.find(element => element === resume.lastName)
    ? resume.name.split(" ")[1] + " " + resume.name.split(" ")[2]
    : resume.lastName

  const isBright = IsColorBright(template.backgroundColor)
  const invertedText = isBright ? "text-qlblack" : "text-qlwhite"

  return (
    <div
      style={{ backgroundColor: template.backgroundColor }}
      className="flex flex-col justify-center relative hero"
    >
      <div className="w-full pl-4 md:pl-12 print:pt-12 print:pl-12">
        <h4
          className={`resume-name-text overflow-visible truncate ${invertedText}`}
        >
          {resume.firstName}
        </h4>
        <h4
          className={`resume-name-text overflow-visible truncate ${invertedText}`}
        >
          {properLastname}
        </h4>
        <h6 className={`resume-role-text mt-2 overflow-visible ${invertedText}`}>
          {resume.role}
        </h6>
      </div>
      <div className={`flex w-full justify-end multiply`}>
        <img
          src={resume.image.url}
          alt={resume.image.alt}
          style={{ width: "368px", height: "368px" }}
          className={`object-fit resume-image ${
            !isBright ? "inverted-profile" : ""
          }`}
        />
      </div>
    </div>
  )
}

export default ResumeImageHeader
