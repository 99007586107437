import React from "react"

const Skill = props => {
  const { skill, template } = props
  return (
    <span
      className="mb-5px mr-5px py-1 px-2 text-qlresumegray rounded-5px resume-skill-box truncate"
      style={{
        backgroundColor: template === "queenslab" ? "#FBF2F2" : "#DADADA",
      }}
    >
      {skill}
    </span>
  )
}

export default Skill
