import React, { Fragment } from "react"

const Education = props => {
                             let coursesTitle = "Kurser i urval:"
                             const { title, education } = props

                             const renderCourses = courses => {
                               if (courses === null) {
                                 return
                               }

                               const courseArray = courses.text.split(", ")

                               if (courseArray.length === 0) {
                                 return
                               }

                               return (
                                 <span>
                                   <h6>{coursesTitle}</h6>
                                   <ul className="list-disc pl-5">
                                     {courseArray?.map((item, i) => {
                                       return (
                                         <li key={"course " + i}>
                                           <p>{item}</p>
                                         </li>
                                       )
                                     })}
                                   </ul>
                                 </span>
                               )
                             }

                             return (
                               <Fragment>
                                 <div className="flex-col dont-break p-0 md:p-4">
                                   <div className="flex px-6 md:px-0 print:px-0">
                                     <div className="md:pl-12 pr-10 hidden md:block print:block print:pl-12">
                                       <svg
                                         className="resume-svg-icons"
                                         xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 384 383.99"
                                       >
                                         <defs>
                                           <clipPath
                                             id="clip-path"
                                             transform="translate(0 -0.01)"
                                           >
                                             <rect
                                               className="svg-img-fill"
                                               width="384"
                                               height="384"
                                             />
                                           </clipPath>
                                         </defs>
                                         <g id="Lager_2" data-name="Lager 2">
                                           <g
                                             id="Lager_1-2"
                                             data-name="Lager 1"
                                           >
                                             <g className="svg-img-fill">
                                               <path
                                                 className="svg-img-fill"
                                                 d="M375.48,64.75,196.34.75a13.23,13.23,0,0,0-8.74,0L8.51,64.75a12.8,12.8,0,0,0,0,24.09L96,120.1V160c0,24.88,86.18,25.6,96,25.6s96-.72,96-25.6V120.1l19.2-6.82V128A12.8,12.8,0,0,0,320,140.8h12.8A12.8,12.8,0,0,0,345.6,128V108.8a18.88,18.88,0,0,0-2.09-8.52l32-11.44a12.8,12.8,0,0,0,0-24.09ZM275.2,145.92c-24.38-11.1-75.74-11.52-83.2-11.52s-58.82.42-83.2,11.52V90.16c3.84-4.93,34-13.36,83.2-13.36s79.36,8.43,83.2,13.36ZM192,172.8c-47.36,0-77.1-7.82-82.66-12.8,5.56-5,35.31-12.8,82.66-12.8S269.1,155,274.66,160c-5.56,5-35.3,12.8-82.66,12.8ZM310.2,98.59,288,106.51V89.6C288,64.72,201.82,64,192,64s-96,.72-96,25.6v16.9L12.8,76.8l179.29-64L320,58.51V90.78a19.15,19.15,0,0,0-9.8,7.81ZM332.8,128H320V108.8a6.4,6.4,0,0,1,12.8,0Zm0-37.48V63.09L371.2,76.8Z"
                                                 transform="translate(0 -0.01)"
                                               />
                                               <path
                                                 className="svg-img-fill"
                                                 d="M364.8,198.4a6.12,6.12,0,0,0-1.14.1A1071.23,1071.23,0,0,1,192,211.2,1071.15,1071.15,0,0,1,20.34,198.5a7.21,7.21,0,0,0-1.14-.1c-4.48,0-19.2,5.08-19.2,70.4s14.72,70.4,19.2,70.4a8.47,8.47,0,0,0,1.14-.11,999.21,999.21,0,0,1,131.85-12l-12.5,30a12.75,12.75,0,0,0,16.92,16.43l4.89-2.09,2.12,4.87A12.73,12.73,0,0,0,175.39,384h.16a12.76,12.76,0,0,0,11.73-8L198.4,349.4l11.08,26.51A12.79,12.79,0,0,0,221.25,384h.19a12.7,12.7,0,0,0,11.72-7.68l2.14-4.91,4.82,2.07A12.75,12.75,0,0,0,257.07,357L244.82,327.6a976.6,976.6,0,0,1,118.87,11.52,5.87,5.87,0,0,0,1.11.08c4.48,0,19.2-5.08,19.2-70.4s-14.72-70.4-19.2-70.4ZM212.48,223.81a134.09,134.09,0,0,1,4.61,29.93,44.4,44.4,0,0,0-44.55,4.14,173.56,173.56,0,0,0-4.39-34.12q11.63.24,23.85.24c7,0,13.79-.08,20.48-.19ZM198.4,326.4a32,32,0,1,1,32-32,32,32,0,0,1-32,32ZM19.2,214.4a179.71,179.71,0,0,1,6.4,54.4,179.71,179.71,0,0,1-6.4,54.4,179.71,179.71,0,0,1-6.4-54.4,179.71,179.71,0,0,1,6.4-54.4ZM32.13,324.29c3.5-10.15,6.27-27.14,6.27-55.49s-2.77-45.34-6.28-55.5a1124.11,1124.11,0,0,0,122.66,10.13A164.87,164.87,0,0,1,160,268.8c0,.93,0,1.81,0,2.7a44.34,44.34,0,0,0-2.06,41.93l-.28.68A1114.21,1114.21,0,0,0,32.13,324.3ZM175.41,371.2l-4.68-10.75a6.39,6.39,0,0,0-8.39-3.33l-10.77,4.76,15-36a44.75,44.75,0,0,0,22.6,12.35Zm59.05-14.08a6.39,6.39,0,0,0-8.39,3.33l-4.74,10.64-13.72-32.85a44.71,44.71,0,0,0,22.62-12.36l15,35.84Zm129.06-31a1037.25,1037.25,0,0,0-124.16-11.52l-.45-1.1A44.56,44.56,0,0,0,230.28,263a178.54,178.54,0,0,0-4.57-39.43,1069.14,1069.14,0,0,0,137.81-12.05c3,5.05,7.68,25,7.68,57.29s-4.74,52.24-7.68,57.28Z"
                                                 transform="translate(0 -0.01)"
                                               />
                                             </g>
                                           </g>
                                         </g>
                                       </svg>
                                     </div>

                                     <div className="flex-col md:pr-32 print:pr-32">
                                       <h4 className="resume-title-text">
                                         {title}
                                       </h4>
                                       {education?.map((item, i) => {
                                         return (
                                           <article
                                             key={"education" + i}
                                             className="mb-12 mt-6 dont-break"
                                           >
                                             <div className="flex-col justify-between max-w-2xl">
                                               <h6 className="resume-experience-role-text mb-2">
                                                 {item.school.text}
                                               </h6>
                                               <h6 className="resume-experience-company-text mb-2">
                                                 {item.duration.text}
                                               </h6>
                                               <p className="resume-presentation-text mb-1 uppercase">
                                                 {item.subject.text}
                                               </p>
                                               <p className="resume-presentation-text">
                                                 {item.summary.text}
                                               </p>
                                             </div>
                                             {item.courses?.length > 0 &&
                                               item.courses[0]?.text && (
                                                 <div className="skills mt-2 flex flex-wrap pt-2">
                                                   {renderCourses(item.courses)}
                                                 </div>
                                               )}
                                           </article>
                                         )
                                       })}
                                     </div>
                                   </div>
                                 </div>
                               </Fragment>
                             )
                           }

export default Education
